
const ai_embeds: Array<{
    clinic: string
    code: string,
    link: string,
}> = [
        {
            clinic: "gtlemon",
            code: "customer",
            link: "https://lemon-ai.web.app"
        },
        {
            clinic: "gtpiti",
            code: "customer",
            link: "https://lemon-ai.web.app"
        },
        {
            clinic: "gtthequeen",
            code: "customer",
            link: "https://queen-ai1.web.app"
        }

    ];

export const getAI = (clinic: string, code: string) => {
    const find = ai_embeds.findIndex(be =>
        be.clinic.toLocaleLowerCase() === clinic.toLocaleLowerCase() && be.code === code);
    if (find !== -1) {
        return ai_embeds[find];
    }
    return null;
}