



const EmbeddedWebsite = ({ src, title }: { src: string; title: string }) => {
    return (
        <div style={{
            zIndex: 50,
            width: '100vw',
            height: '100vh',
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        
        }}>
            <iframe
                src={src}
                title={title}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    bottom:'0',
                }}
                allowFullScreen
            />
        </div>
    );
};


const EmbedModal = (args: {
    src: string,
    title: string,
}) => {
    return (
        <EmbeddedWebsite
            src={args.src}
            title={args.title}
        />
    );
};

export default EmbedModal;
