import { useEffect, useState } from 'react';
import { getAI } from '../ai-chat';
import withClinic from '../hooks/with_clinic';
import AIContainer from './container';
import Unauthorized from '../bi-dashboard/unauthorized';

function ServiceBIDashboard(props: any) {
    const [ai, setAI] = useState(getAI(props?.currentClinic?.code || "", 'customer'));
    useEffect(() => {
        if (props.currentClinic) {
            setAI(getAI(props?.currentClinic?.code || "", 'customer'));
        }
    }, [props.currentClinic])

    if (ai) return <AIContainer title='AI Chat' src={ai.link} />
    return <Unauthorized />
}

export default withClinic(ServiceBIDashboard);
