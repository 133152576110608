

const bi_embeds: Array<{
    clinic: string
    code: string,
    link: string,
}> = [
        {
            clinic: "gtthequeen",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/72aa4201-2746-40dd-acc0-133a7128b747/page/OoV4D"
        },
        {
            clinic: "gtthequeen",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/601da302-2e46-43a1-ac9b-a59ae0cf7a8a/page/OoV4D"
        },
        {
            clinic: "gtdrmin",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/200a2a7a-85df-4c24-a5c1-53f065ab1757/page/OoV4D"
        },
        {
            clinic: "gtdrmin",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/d4c1a92b-f230-41ab-a511-4f3af53b1542/page/OoV4D"
        },
        {
            clinic: "gtpure",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/f54da130-0503-47f2-890b-f3e8c63fe50c/page/OoV4D"
        },
        {
            clinic: "gtpure",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/1af3b0cf-0a71-4024-bf90-5686233a92c7/page/OoV4D"
        },
        {
            clinic: "gtdrko",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/6af99232-738b-4af9-89f1-53da5c80a0e5/page/OoV4D"
        },
        {
            clinic: "gtdrko",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/103e9f99-5089-464c-a10d-c8dd6f7fe4f1/page/OoV4D"
        },
        {
            clinic: "gtlemon",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/6cafc83f-3bc8-47c2-9674-a99022b070bc/page/OoV4D"
        },
        {
            clinic: "gtlemon",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/abca5eef-c47c-4ec5-af4e-3a01d5242eda/page/OoV4D"
        },

        //fancyhouse group
        {
            clinic: "gtfancyhousega",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/cd3f7a6e-0115-4e13-be52-0bc87921ea03/page/OoV4D",
        },
        {
            clinic: "gtfancyhousemp",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/cd3f7a6e-0115-4e13-be52-0bc87921ea03/page/OoV4D",
        },
        {
            clinic: "gtfancyhousepl",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/cd3f7a6e-0115-4e13-be52-0bc87921ea03/page/OoV4D",
        },

        //fancyhouse customer
        {
            clinic: "gtfancyhousega",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/8d79a249-809a-45ed-884d-78f32b394857/page/OoV4D",
        },
        {
            clinic: "gtfancyhousemp",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/8d79a249-809a-45ed-884d-78f32b394857/page/OoV4D",
        },
        {
            clinic: "gtfancyhousepl",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/8d79a249-809a-45ed-884d-78f32b394857/page/OoV4D",
        },
        // dsc 
        {
            clinic: "gtdsc",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/3e613f97-7679-4d20-9d1c-341fec4c6865/page/OoV4D",
        },
        {
            clinic: "gtdsc",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/07e3869e-1e27-440f-b0f2-7fb4859be6c3/page/OoV4D",
        },
        // sk
        {
            clinic: "gtsk",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/c9dd7b68-aa77-4059-af99-4f19291890f9/page/OoV4D",
        },
        {
            clinic: "gtsk",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/07e3869e-1e27-440f-b0f2-7fb4859be6c3/page/OoV4D",
        },

        // gtlasercare
        {
            clinic: "gtlasercare",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/30f66df2-1c8a-4aa2-9ff9-50fd54a31f08/page/OoV4D",
        },
        {
            clinic: "gtlasercare",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/e44495aa-46ab-4106-a796-966b053d428b/page/OoV4D",
        },

        // gtdrrejuve
        {
            clinic: "gtdrrejuve",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/6eeb34ed-d293-4397-bd5c-95d5fbaa8aa6/page/OoV4D",
        },
        {
            clinic: "gtdrrejuve",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/8fd5a3b3-89d5-45ba-8d80-b9f6ae340d69/page/OoV4D",
        },
    ];


export const getBI = (clinic: string, code: string) => {
    const find = bi_embeds.findIndex(be =>
        be.clinic.toLocaleLowerCase() === clinic.toLocaleLowerCase() && be.code === code);
    if (find !== -1) {
        return bi_embeds[find];
    }
    return null;
}
