import React, { useEffect, useState } from "react";
import withClinic from "../hooks/with_clinic";
import { ApolloResult, Clinic, Service } from "../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../components/date_range";
import { Form, message } from "antd";
import { Table, Button, Popconfirm } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import MultiSelect from "../components/multi_select";
import { formatMoney } from "../helpers/utils";
import { Avatar } from "antd";
import {
  getServicesVar,
  getUpdateOneServiceVar,
  get_services,
  update_one_service,
} from "../graphql/service";
import { Link, useNavigate } from "react-router-dom";
import paths from "../routes/paths";
import { EditOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { InputNumber } from "antd";
import { Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
interface P {
  currentClinic?: Clinic;
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea";
  record: any;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode =
    inputType === "number" ? (
      <InputNumber
        min={dataIndex === "interval_day" ? 1 : Number.MIN_SAFE_INTEGER}
      />
    ) : inputType === "switch" ? (
      <Switch defaultChecked={record.status === "ACTIVE"} />
    ) : (
      <Input />
    );
  if (inputType === "textarea") {
    inputNode = (
      <TextArea
        key={`${dataIndex}`}
        rows={3}
        defaultValue={(record as any)[dataIndex]}
      />
    );
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ServicePage = (props: P) => {
  const navigate = useNavigate();
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [services, setServices] = useState<Service[]>([]);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: addDays(new Date(), 15),
  });
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: Service) => record.id === editingKey;
  const [form] = Form.useForm();
  const variables = getServicesVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: 150,
    status: filter,
  });
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"services", Service[]>
  >(get_services, { variables });
  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneService", Service>>(update_one_service);

  useEffect(() => {
    if (data) {
      const services = data.services.map((s) => ({
        ...s,
        interval_day:
          s?.interval_day == null || s?.interval_day === 0 ? 7 : s.interval_day,
      }));
      setServices(services);
    }
  }, [data]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!services) return <Empty />;
  const edit = (r: Service) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const handleSave = async (r: Service) => {
    let fData = (await form.validateFields()) as {
      status: string | boolean;
      price: string | number;
      original_price: string | number;
      interval_day: number;
      sort_order: number;
    };
    if (typeof fData.status === "boolean") {
      fData = {
        ...fData,
        status: fData.status === true ? "ACTIVE" : "INACTIVE",
      };
    }
    fData = {
      ...fData,
      price: `${fData.price}`,
      original_price: `${fData.original_price}`,
      interval_day: fData.interval_day || 0,
      sort_order: Number(fData.sort_order),
    };
    const variables = getUpdateOneServiceVar(r.id, fData);
    const result = await update({ variables });
    if (result.data?.updateOneService) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
    setEditingKey("");
  };
  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      editable: false,
      render: (v: number, r: Service) => {
        return (
          <Link to={paths.getServiceDetailRoute(r.id)}>
            {" "}
            <Avatar src={r.image} size={50} />
          </Link>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: false,
      sorter: (a: Service, b: Service) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      defaultSortOrder: "ascend",
      render: (v: number, r: Service) => {
        return <Link to={paths.getServiceDetailRoute(r.id)}>{r.name}</Link>;
      },
    },
    // {
    //     title: 'ID',
    //     dataIndex: "id",
    //     key: "id",
    //     editable: false,
    //     // sorter: (a: Service, b: Service) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    // },

    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Service, b: Service) => a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a: Service, b: Service) => Number(a.price) - Number(b.price),
      editable: true,
      render: (v: number, r: Service) => {
        return `${formatMoney(Number(r.price))} MMK`;
      },
    },
    {
      title: "Original Price",
      dataIndex: "original_price",
      key: "original_price",
      sorter: (a: Service, b: Service) =>
        Number(a.original_price) - Number(b.original_price),
      editable: true,
      render: (v: number, r: Service) => {
        return `${formatMoney(Number(r.original_price))} MMK`;
      },
    },
    {
      title: "Order",
      dataIndex: "sort_order",
      key: "sort_order",
      editable: true,
      sorter: (a: Service, b: Service) =>
        Number(a.sort_order) - Number(b.sort_order),
    },
    {
      title: "Duration (Min)",
      dataIndex: "duration",
      key: "duration",
      editable: true,
      sorter: (a: Service, b: Service) => a.sort_order - b.sort_order,
    },
    {
      title: "Limit Per Duration",
      dataIndex: "max_duration_count",
      key: "max_duration_count",
      editable: true,
      sorter: (a: Service, b: Service) => a.sort_order - b.sort_order,
    },
    {
      title: "Service Reminder (Days)",
      dataIndex: "interval_day",
      key: "interval_day",
      editable: true,
      sorter: (a: Service, b: Service) => a.sort_order - b.sort_order,
    },

    {
      title: "CreatedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Service, b: Service) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Service, r: Service) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: Service) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              <Popconfirm
                placement="topRight"
                title={`Sure to save ${record.name}?`}
                onConfirm={() => handleSave(record)}
                onCancel={() => setEditingKey("")}
              >
                <Button
                  type="primary"
                  loading={saveLoading}
                  icon={saveLoading ? <LoadingOutlined /> : <SaveOutlined />}
                >
                  save
                </Button>
              </Popconfirm>
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    const isNumber = () => {
      if (col.dataIndex === "duration") {
        return true;
      }
      if (col.dataIndex === "max_duration_count") {
        return true;
      }
      if (col.dataIndex === "price") {
        return true;
      }
      if (col.dataIndex === "original_price") {
        return true;
      }
      if (col.dataIndex === "commission") {
        return true;
      }
      if (col.dataIndex === "sort_order") {
        return true;
      }
      if (col.dataIndex === "interval_day") {
        return true;
      }
      return false;
    };

    const isSwitch = () => {
      if (col.dataIndex === "status") {
        return true;
      }
      return false;
    };
    return {
      ...col,
      onCell: (record: Service) => ({
        record,
        inputType: isNumber() ? "number" : isSwitch() ? "switch" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>({services.length}) Services</Typography>
          </Col>

          <Col span={8} offset={8}>
            {/* <AutoComplete
            defaultValue={searchText || undefined}
             onSearch={(val: any) => setSearchText(val)}>
              <Input.Search placeholder="Search...." enterButton/>
            </AutoComplete> */}
            <Search
              defaultValue={searchText || undefined}
              placeholder={`"search  ...."`}
              allowClear
              size="middle"
              // onChange={(val:any)=>setSearchText(val)}
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );

  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={24}>
        <MultiSelect
          selected={filter}
          placeholder="Select service status"
          options={["ACTIVE", "INACTIVE"]}
          onChange={setFilter}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            showHeader={services.length > 0}
            components={{ body: { cell: EditableCell } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={services.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(ServicePage);
