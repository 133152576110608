import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  RefType,
  SaleChannel,
  SaleChannelInput,
  SaleChannelMutate,
  SelectType,
  Status,
  TotalIdCount,
} from "../../store";
import { Row, Col, Typography, Empty, Button, Popconfirm, Input } from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import { Switch } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import {
  getSaleChannelListVar,
  create_sale_channel,
  sale_channel_list,
  update_sale_channel,
  getUpdateOneSaleChannelVar,
  get_sale_channel_count,
  detete_sale_channel,
  getDeleteOneSaleChannelVar,
  getSaleChannelMetaData,
} from "../../graphql/sale_channel";
import { InputNumber } from "antd";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import RefSelect from "./ref_select";
import { Space } from "antd";
import ImagUpload from "../../components/image_upload";
import { Avatar } from "antd";

interface P {
  currentClinic?: Clinic;
}

interface SaleChannelForm extends SaleChannelInput {
  key: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "text" | "select" | "switch" | "date" | "number" | "img";
  record: SaleChannelInput;
  regularExpression?: RegExp;
  index: number;
  children: React.ReactNode;
  options?: Array<SelectType>;
  clinicId: string;
  refChange?: (value?: string) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  regularExpression,
  options,
  clinicId,
  refChange,
  ...restProps
}) => {
  const inputNode =
    dataIndex === "ref" ? (
      <RefSelect
        defaultValue={(record as any)[dataIndex]}
        clinicId={clinicId}
        setImage={refChange}
      />
    ) : inputType === "select" ? (
      <Select
        defaultValue={(record as any)[dataIndex]}
        options={options ?? []}
        style={{ minWidth: 130 }}
      />
    ) : inputType === "number" ? (
      <InputNumber style={{ minWidth: 114 }} />
    ) : inputType === "switch" ? (
      <Switch defaultChecked={(record as any)[dataIndex]} />
    ) : inputType === "date" ? (
      <DatePicker
        style={{ minWidth: 120 }}
        disabledDate={(currentDate: Moment) => currentDate.isBefore(new Date())}
      />
    ) : inputType === "img" ? (
      <ImagUpload
        value={(record as any)[dataIndex]}
        defaultValue={(record as any)[dataIndex]}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required:
                dataIndex === "ref" ||
                dataIndex === "price" ||
                dataIndex === "original_price" ||
                dataIndex === "count" ||
                dataIndex === "order" ||
                dataIndex === "channel"
                  ? true
                  : false,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SALECHANNEL_OPTIONS = [
  { value: "alifestyleprod", label: "ALifeStyle" },
  { value: "alifestyleuat", label: "ALifeStyle UAT" },
  { value: "gtlifestyle", label: "GreatTime LifeStyle" },
];

const SaleChannelPage = (props: P) => {
  const clinicId = props.currentClinic?.id ?? "";
  const companyId = props.currentClinic?.company_id ?? "";
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [pagination, setPagination] = useState<PaginationConfig | null>(null);
  const [saleChannels, setSaleChannels] = useState<SaleChannelInput[]>([]);
  // const [companies, setCompaines] = useState<SelectType[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [deletingId, setDeletingId] = useState<string | undefined>();

  const take = pagination?.pageSize || 20;
  const skip = ((pagination?.current || 1) - 1) * take;
  const isEditing = (record: SaleChannelInput) => record.id === editingKey;

  const variables = getSaleChannelListVar({
    clinicId,
    searchText,
    take,
    skip,
  });

  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"saleChannels", SaleChannel[]>
  >(sale_channel_list, { variables, fetchPolicy: "cache-and-network" });
  const { data: totalCountData } = useQuery<
    ApolloResult<"aggregateSaleChannel", TotalIdCount>
  >(get_sale_channel_count, { variables, fetchPolicy: "cache-and-network" });
  // const { data: companyData } = useQuery<
  //   ApolloResult<"companies", CompanySelect[]>
  // >(get_company_for_sale_channel, {
  //   variables: getCompanySaleChannelVar(),
  //   fetchPolicy: "cache-and-network",
  // });

  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneSaleChannel", SaleChannelMutate>>(
      update_sale_channel
    );
  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOneSaleChannel", SaleChannelMutate>>(
      create_sale_channel
    );
  const [deleteSaleChannel, { loading: deleteLoading }] =
    useMutation<ApolloResult<"deleteOneSaleChannel", SaleChannelMutate>>(
      detete_sale_channel
    );

  useEffect(() => {
    if (data?.saleChannels) {
      const saleChannels = data.saleChannels.map((b) => ({
        ...b,
        status: b.status === "ACTIVE",
        price: b.price + "",
        original_price: b.original_price + "",
        expire_at: moment(b.expire_at),
        ref_image: b.ref_image,
        ref: {
          ref_id: b.ref_id,
          ref_type: b.ref_type,
          ref_name: b.ref_name,
          ref_image: b.ref_image,
        },
      }));
      setSaleChannels(saleChannels);
    }
  }, [data]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;

  const edit = (r: SaleChannelInput) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };

  const add = () => {
    const now = new Date();
    const newData: SaleChannelInput = {
      id: `new_${new Date().getTime()}`,
      channel: "",
      count: 1,
      created_at: now,
      expire_at: moment([
        now.getFullYear() + 10,
        now.getMonth(),
        now.getDate(),
      ]),
      order: 1,
      original_price: "0",
      price: "0",
      ref: {
        ref_id: "",
        ref_image: "",
        ref_name: "",
        ref_type: RefType.service,
      },
      status: true,
    };
    const newList = [...saleChannels];
    newList.unshift(newData);
    setSaleChannels(newList);
    setEditingKey(newData.id);
    form.resetFields();
  };

  const save = async (saleChannel: SaleChannelForm) => {
    if (!clinicId || !companyId) {
      message.error("Can't create user with no Clinic data.");
      return;
    }
    if (saleChannel.id.startsWith("new_")) {
      try {
        await form.validateFields();
        await onCreate();
      } catch {
        return;
      }
    } else {
      try {
        const formData = await form.validateFields();

        formData.status =
          typeof formData.status == "string"
            ? formData.status
            : formData.status
            ? "ACTIVE"
            : "INACTIVE";
        formData.ref.ref_image = formData.ref_image;
        const result = await update({
          variables: getUpdateOneSaleChannelVar(saleChannel.id, formData),
        });
        if (result.data?.updateOneSaleChannel) {
          message.success("Sale channel update success");
          await refetch();
        } else {
          message.error("Sale channel update failed");
        }
      } catch (error) {
        console.log("Sale channel update error:", error);
        return;
      }
    }
    form.resetFields();
    setEditingKey("");
  };

  const deleteSaleChannelHandler = (id: string) => {
    deleteNewSaleChannel(id);
  };

  const deleteNewSaleChannel = (id: string) => {
    if (id.startsWith("new_")) {
      const newUsers = saleChannels.filter((user) => user.id !== id);
      setSaleChannels(newUsers);
    }
  };

  const onCreate = async () => {
    try {
      const { id, ref, price, original_price, ...formData } =
        form.getFieldsValue();

      const expire_at = formData.expire_at;
      const status = formData.status ?? true;
      const now = new Date();
      const metadata = getSaleChannelMetaData({ ref });

      formData.status = status ? Status.ACTIVE : Status.INACTIVE;
      formData.ref_id = ref.ref_id;
      formData.ref_type = ref.ref_type;
      formData.ref_name = ref.ref_name;
      formData.price = price + "";
      formData.original_price = original_price + "";
      formData.expire_at =
        expire_at ??
        new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
      if (metadata != null) formData.metadata = metadata;

      const result = await create({
        variables: {
          data: {
            ...formData,
            clinic: {
              connect: {
                id: clinicId,
              },
            },
          },
        },
      });
      if (result.data?.createOneSaleChannel) {
        message.success("Sale channel creation success");
        await refetch();
      } else {
        message.error("Sale channel creation failed");
      }
    } catch (error: unknown) {
      console.error("Sale channel creation error:", error);
      // return;
      // throw error;
    }
  };

  const deleteOne = async (saleChannel: SaleChannelForm) => {
    try {
      setDeletingId(saleChannel.id);
      const result = await deleteSaleChannel({
        variables: getDeleteOneSaleChannelVar(saleChannel.id),
      });
      if (result.data?.deleteOneSaleChannel) {
        message.success("Sale channel delete success");
        await refetch();
      } else {
        message.error("Sale channel delete failed");
      }
    } catch (error) {
      console.log("Sale channel delete error:", error);
      return;
    } finally {
      setDeletingId(undefined);
    }
  };

  const onCancel = async (saleChannel: SaleChannelInput) => {
    if (saleChannel.id.startsWith("new_")) {
      const newList = [...saleChannels];
      newList.shift();
      setSaleChannels(newList);
    }
    setEditingKey("");
  };

  const refChange = (value?: string) => {
    if (editingKey == "") return;
    const newList = [...saleChannels];
    const index = newList.findIndex((c) => c.id === editingKey);

    if (index === -1) return;
    newList[index].ref_image = value;
    setSaleChannels(newList);
  };

  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "ref_image",
      key: "ref_image",
      editable: true,
      render: (v: number, r: SaleChannelInput) => {
        return <Avatar src={r.ref_image} size={50} />;
      },
    },
    {
      title: "Name",
      dataIndex: "ref",
      key: "ref",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => r.ref.ref_name,
      sorter: (a: SaleChannelInput, b: SaleChannelInput) =>
        a.ref.ref_name
          .toLowerCase()
          .localeCompare(b.ref.ref_name.toLowerCase()),
    },
    {
      title: "Type",
      dataIndex: "ref.ref_type",
      key: "ref_type",
      editable: false,
      filteredValue: searchText ? [searchText] : null,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => {
        const refType = r.ref.ref_type;
        return refType.charAt(0).toUpperCase() + refType.slice(1);
      },
      sorter: (a: SaleChannelInput, b: SaleChannelInput) =>
        a.ref.ref_type
          .toLowerCase()
          .localeCompare(b.ref.ref_type.toLowerCase()),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => {
        return (
          SALECHANNEL_OPTIONS.find((x) => x.value === r.channel)?.label ??
          r.channel
        );
      },
      sorter: (a: SaleChannelInput, b: SaleChannelInput) =>
        a.channel.toLowerCase().localeCompare(b.channel.toLowerCase()),
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      editable: true,
      sorter: (a: SaleChannelInput, b: SaleChannelInput) => a.order - b.order,
    },
    {
      title: "Acitve",
      dataIndex: "status",
      key: "status",
      editable: true,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => {
        return r.status ? "ACTIVE" : "INACTIVE";
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      editable: true,
      width: 124,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => {
        return `${r.price} MMK`;
      },
    },
    {
      title: "Original Price",
      dataIndex: "original_price",
      key: "original_price",
      editable: true,
      width: 124,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => {
        return `${r.original_price} MMK`;
      },
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      editable: true,
      sorter: (a: SaleChannelInput, b: SaleChannelInput) => a.count - b.count,
    },
    {
      title: "Expire At",
      dataIndex: "expire_at",
      key: "expire_at",
      editable: true,
      render: (_v: SaleChannelInput, r: SaleChannelInput) => {
        const expireAt = moment(r.expire_at).format("YYYY/MM/DD");
        return expireAt;
      },
      sorter: (a: SaleChannelInput, b: SaleChannelInput) => a.count - b.count,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: SaleChannelForm) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading || createLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.ref.ref_name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Space>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => edit(record)}
                />
                {deleteLoading && deletingId === record.id ? (
                  <Button icon={<LoadingOutlined />}>deleting...</Button>
                ) : (
                  <Popconfirm
                    placement="topRight"
                    title={`Sure to delete ${record.ref.ref_name}?`}
                    onConfirm={() => deleteOne(record)}
                  >
                    <Button icon={<DeleteOutlined />} />
                  </Popconfirm>
                )}
              </Space>
            )}
            {record.id.startsWith("new_") && (
              <Popconfirm
                placement="topRight"
                title={`Sure to delete ${record.ref.ref_name}?`}
                onConfirm={() => deleteSaleChannelHandler(record.id)}
              >
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col, index) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: SaleChannelInput) => ({
        record,
        inputType:
          col.dataIndex === "ref" || col.dataIndex === "channel"
            ? "select"
            : col.dataIndex === "status"
            ? "switch"
            : col.dataIndex === "expire_at"
            ? "date"
            : col.dataIndex === "channel"
            ? "select"
            : col.dataIndex === "ref_image"
            ? "img"
            : col.dataIndex === "order" ||
              col.dataIndex === "count" ||
              col.dataIndex === "original_price" ||
              col.dataIndex === "price"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        index,
        title: col.title,
        editing: isEditing(record),
        clinicId: clinicId,
        options: col.dataIndex === "channel" ? SALECHANNEL_OPTIONS : undefined,
        refChange: col.dataIndex === "ref" ? refChange : undefined,
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Sale Channels</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={saleChannels.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={saleChannels.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            onChange={(pagination: PaginationConfig) => {
              setPagination(pagination);
            }}
            sroll={{ x: true }}
            pagination={{
              current: pagination?.current || 1,
              pageSize: take,
              total: totalCountData?.aggregateSaleChannel._count.id || 0,
              showSizeChanger: false,
              hideOnSinglePage: true,
            }}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(SaleChannelPage);
